export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const DETERMINE_FAN_FAVORITES = 'DETERMINE_FAN_FAVORITES';
export const FINISH_INITIAL_LOAD = 'FINISH_INITIAL_LOAD';
export const LOAD_TOP_TWENTY_MOST_POPULAR = 'LOAD_TOP_TWENTY_MOST_POPULAR';
export const LOAD_TOP_TWENTY_HIGHEST_RANKED = 'LOAD_TOP_TWENTY_HIGHEST_RANKED';
export const SET_FOCUSED_SERIES = 'SET_FOCUSED_SERIES';
export const SET_RECENTLY_VIEWED_SERIES = 'SET_RECENTLY_VIEWED_SERIES';
export const SET_SEARCH_BAR_INPUT = 'SET_SEARCH_BAR_INPUT';
export const SET_SEARCH_BAR_VISIBILITY = 'SET_SEARCH_BAR_VISIBILITY';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const TOGGLE_SEARCH_BAR_VISIBILITY = 'TOGGLE_SEARCH_BAR_VISIBILITY';